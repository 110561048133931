import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import "./gds.css"

const GDS = () => (
  <Layout>
    <SEO title="Artxcode client | GDS" />
    <div className="gds-header">
      <div className="gds-header-inner">
        <p className="gds-header-inner-subtitle">Client</p>
        <h1>Government Digital Service</h1>
      </div>
    </div>
    <div className="gds-content">
      <div className="gds-content-inner">
        <h2>The client</h2>
        <p>
          Government Digital Service (GDS) is part of the Cabinet Office and leads the digital transformation of the UK government. It builds platforms, products and services that help create a simple, joined-up and personalised experience of government to everyone.
          Some of it's responsibilities include providing vital information and services for users through GOV.UK; maintaining and improving the services and tools they provide to the rest of government; 
          increasing the use of shared platforms and components across government; and supporting departments by strengthening their digital capability and providing direct support for major digital projects.
        </p>
        <h2>The work</h2>
        <p>
          Artxcode was hired to work with the Domains Management team within GDS, to support the development of a group of Discovery and Alpha services, which included finding abusive domains, as well as detailed
          tracking of the public sector's use of cloud services. It also included productionising existing code to protect the .gov.uk domain namespace, other domain namespaces, and some of the services that use these namespaces.

           {/* on a specific toolset designed to allow it's users to monitor  */}
        </p>
        <p>
          The work involved supporting the refactoring of the proof of concept domain management toolset into a resilient and supportable domains platform, as well as
          extending the toolset's scope to provide facilities for other authorities to monitor their national namespaces, specifically .nhs.uk, .nhs.wales, .nhs.scot, .gov.scot, .gov.wales, .ac.uk, .sch.uk, among others.
        </p>

        <p>
          It was also ensured that the developed product met the functional, performance, security and availability requirements of the agreed product specification, and could be handed over to a future team with no loss of knowledge. 
        </p>

        <p>Artxcode provided both Software Developement and DevOps expertise across the project. The technologies used include Python, Django, Docker, PostgreSQL and AWS. Part of the existing prototype had been developed in other languages,
          which required familiarity in Bash, Perl, as well as some operating system dependencies such as OpenSSL, SED, AWK, among others.</p>
      </div>
    </div>
  </Layout>
)

export default GDS
